<template>
  <el-container class="patroladd">
     
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>自查自纠</el-breadcrumb-item>
       <el-breadcrumb-item >自查自纠列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.role_id">编辑自查自纠</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增自查自纠</el-breadcrumb-item>
    </el-breadcrumb>

        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="">
    <el-form  :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
      <el-row>
         <el-col :span="11" class="tl" >
           <el-form-item label="经营者姓名："  class="w" label-width="28%"  prop='operator'  :rules="[{required: true,message: '请输入经营者姓名', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
          <el-input disabled v-model="form.operator" size="medium"  clearable></el-input>
        </el-form-item>
        </el-col>
      </el-row>
         <el-table border :data="menu_list" style="width: 100%">
      
        <el-table-column type="index" align='center' width="60" label="序号"></el-table-column>
       <el-table-column prop="attr_ch" min-width="150" :show-overflow-tooltip="true"  label="名称" align='center'></el-table-column>
        <el-table-column prop="attr_note"  min-width="150" :show-overflow-tooltip="true"  label="备注" align='center'></el-table-column>
         <el-table-column label="图片" width="250"   align='center'>
          <template slot-scope="scope">
              <el-image v-if="scope.row.attr_img" :src="scope.row.attr_img" fit="fill" :preview-src-list="[scope.row.attr_img]"  style="width: 40px;height: 40px"></el-image>
               <img v-else src="../../../assets/image/moren.png"   style="width: 40px;height: 40px"/> 
          </template>
        </el-table-column>
          <el-table-column prop="attr_val" label="是否合格" fixed="right" width="200px" align="center">
             <template slot-scope="scope">
              <span class="f12" :class="scope.row.attr_val==='合格'?'c67c':'red'"
              >{{scope.row.attr_val}}</span>
            </template>
          </el-table-column>
      </el-table>
    
      <div class="i-page fr disib mt20">
        <el-pagination
          background
          :page-size='10'
          layout="total,prev, pager, next"
          :total="total"
          :current-page="currentPage"
         @current-change = 'currentChange'>
        </el-pagination>
      </div>
      <div class="tl mt15">
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
          </div>
     </el-form>
     </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session } from '../../../util/util'
import {imageUrl} from '../../../config/index'
export default {
  data () {
    return {
      menu_list:[],
      imageUrl:imageUrl,
      uploadDisabled11: false,
       dialogImageUrl: '',
        dialogVisible: false,
      activeNames: ['1'],
        form: {
          check_self_id:'',
            operator:'',
          content:[]
      },
        user_id_add:'',
        user_name_add:'',
          currentPage: 1,
      total: 0,
      curRowIndex: null,
    }
  },
     created () {
      this.user = local.get('user');
       this.user_id_add=this.user.user_id;
        this.form.operator=this.user.user_name;
       this.form.check_self_id=session.get('params').id?String(session.get('params').id):'';
     this.init()
  },
  methods: {
     init (params) {
          axios.get('/pc/company-cs/single',{check_self_id:this.form.check_self_id}).then((v) => {
               this.form.operator=v.data.company_cs.operator
           this.menu_list=JSON.parse(v.data.company_cs.content)
      })   
    },
    // 分页
    currentChange(page){
        var that=this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10)
      that.init()
    },

      handleChange(){}
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patroladd-title1{height: 34px;line-height: 34px;}
.el-checkbox{    display: flex;
    justify-content: left;
    align-items: center;}
/deep/ .el-collapse-item .el-collapse-item__content{padding-bottom: 0px;}
    /deep/ .el-upload-list--picture-card .el-upload-list__item{width: 160px;height: 160px;}
   }
    .health_url{
          margin-left: 80px;
     /deep/ .el-upload--picture-card{width: 70px;height: 40px;
     background-color: transparent;
    border: none;
     .el-button--medium{position: absolute;left: 32%;;margin-top: 4px;}}

/deep/ .el-upload-list--picture-card .el-upload-list__item{width: 70px;height: 40px;}

   } 
        /deep/ .disabled .el-upload--picture-card {
    display: none !important;
}

</style>
